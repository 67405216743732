import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Risk Disclosures</h2>
          <p>Point50 Capital Services Risk Guidelines</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Customer Guidance</h3>
          <p>
          Digital Currencies represent a speculative investment and involve a high degree of risk. 
          Investors should have the financial ability, sophistication/experience and willingness to 
          bear the risks of an investment. An investment in digital currencies may not suitable for 
          all investors. Digital currencies are NOT considered securities and are NOT subject to 
          the same regulatory requirements as CBK/CMA-registered securities, exchange traded funds, 
          or similar investment vehicles. Potential investors should carefully consider the long 
          term nature of an investment in digital currencies prior to making an investment decision.
          </p>

          <p>
          Bitcoin and digital assets prices are volatile and fluctuate day-to-day. Trading in these 
          digital assets may be considered a high-risk activity. Proper diligence and sound judgement 
          should be used in evaluating the risks associated with these activities. We do not solicit 
          nor make any representation that digital assets are an investment vehicle. The decision to 
          trade digital assets rests entirely on the users own independent judgement.
          </p>

          <h3 className="major">Our Position</h3>
          <p>
          Information issued by Point50 Capital Limited is not intended to be advice nor a 
          recommendation concerning cryptocurrency investment nor an offer or solicitation 
          to buy or sell any cryptocurrency or related financial instrument. While we provide 
          this information in good faith it is not intended to be relied upon by you and we 
          accept no liability nor assume any responsibility for the consequences of any 
          reliance that may be placed upon our services.
          </p>

          <section className="features">
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
